import { toCamel, toUnderline, deepCopy } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from './services'
import { UserWallet } from '@/utils/class'

// 取得使用者錢包餘額
export const GetUserWalletService = (): ApiService<User.UserWallet.Response> => {
  return request.get('/user/wallet')
    .then(res => {
      const result = UserWallet(toCamel(res.data.wallet))
      return { isError: false, value: result } as const
    })
    .catch(err => {
      apiErrorMsg('GetUserWalletService', err.responsze?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 查詢使用者真實資金紀錄
export const GetRealWalletRecord = (query: User.UserWalletRecord.Req = {}): ApiService<User.UserWalletRecord.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/user/wallet/real/record',  { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRealWalletRecord', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 查詢使用者虛擬資金紀錄
export const GetDemoWalletRecord = (query?: User.UserWalletRecord.Req): ApiService<User.UserWalletRecord.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/user/wallet/demo/record',  { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetDemoWalletRecord', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 查詢使用者SOF資金紀錄
export const GetTokenWalletRecord = (query?: User.UserWalletRecord.Req): ApiService<User.UserWalletRecord.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/user/wallet/token/record',  { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetTokenWalletRecord', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得支付通道入金申請表單資訊
export const GetDepositPaymentChannelService = (): ApiService<User.DepositPaymentChannel.Get.Response> => {
  return request.get('/payment/deposit/payment_channel')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetDepositPaymentChannelService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 申請支付通道入金
export const PostPaymentChannelService = (query: User.DepositPaymentChannel.Post.Req): ApiService<User.DepositPaymentChannel.Post.Res> => {
  const body = toUnderline(deepCopy(query))
  return request.post('/payment/deposit/payment_channel/order', body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostPaymentChannelService', err.response?.data?.error)
      return { isError: true, value: err.response.data }
    })
}

// 取得入金方式列表
export const GetDepositWayService = (): ApiService<User.DepositWay.Response> => {
  return request.get('/payment/deposit/way')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetDepositWayService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得電匯入金申請表單資訊
export const GetDepositWireService = (): ApiService<User.DepositWire.Get.Response> => {
  return request.get('/payment/deposit/wire')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetDepositWireService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 申請電匯入金
export const PostDepositWireService = (query: User.DepositWire.Post.Req): ApiService<User.DepositWire.Post.Res> => {
  const body = toUnderline(deepCopy(query))
  return request.post('/payment/deposit/wire/order', body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostDepositWireService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得 HD WALLET 入金申請表單資訊
export const GetDepositHdwalletService = (): ApiService<User.DepositHdwallet.Get.Response> => {
  return request.get('/payment/deposit/hdwallet')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetDepositHdwalletService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得加密貨幣入金申請表單資訊
export const GetDepositCryptoService = (): ApiService<User.DepositCrypto.Get.Response> => {
  return request.get('/payment/deposit/crypto')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetDepositCryptoService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 上傳付款截圖
export const PostDepositImageService = (query: User.DepositUploadImage.Req): ApiService<User.DepositUploadImage.Res> => {
  const formData = new FormData()
  formData.append('image_file', query.imageFile)
  return request.post('/payment/deposit/upload_image', formData)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostDepositImageService', err.response?.data?.error)
      return { isError: true, value: err.response.data }
    })
}

// 申請加密貨幣入金
export const PostDepositCryptoService = (query: User.DepositCrypto.Post.Req): ApiService<User.DepositCrypto.Post.Res> => {
  const body = toUnderline(deepCopy(query))
  return request.post('/payment/deposit/crypto/order', body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostDepositCryptoService', err.response?.data?.error)
      return { isError: true, value: err.response.data }
    })
}

// 申請 HD WALLET 入金
export const PostDepositHdwalletService = (query: User.DepositHdwallet.Post.Req): ApiService<User.DepositHdwallet.Post.Res> => {
  const body = toUnderline(deepCopy(query))
  return request.post('/payment/deposit/hdwallet/order', body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostDepositHdwalletService', err.response?.data?.error)
      return { isError: true, value: err.response.data }
    })
}

// 取得入金訂單列表
export const GetDepositOrderListService = (query?: User.DepositOrderList.Req): ApiService<User.DepositOrderList.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/payment/deposit/orders',  { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetDepositOrderListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得入金訂單詳細資料
export const GetDepositOrderService = (query: User.DepositOrder.Req): ApiService<User.DepositOrder.Res> => {
  return request.get(`/payment/deposit/orders/${query.id}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetDepositOrderService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得取款訂單列表
export const GetWithdrawOrderListService = (query?: User.WithdrawOrderList.Req): ApiService<User.WithdrawOrderList.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/payment/withdraw/orders',  { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetWithdrawOrderListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得取款訂單詳細資料
export const GetWithdrawOrderService = (query: User.WithdrawOrder.Req): ApiService<User.WithdrawOrder.Res> => {
  return request.get(`/payment/withdraw/orders/${query.id}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetWithdrawOrderService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得取款方式列表
export const GetWithdrawWayService = (): ApiService<User.WithdrawWay.Response> => {
  return request.get('/payment/withdraw/way')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetWithdrawWayService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得電匯取款申請表單資訊
export const GetWithdrawWireService = (): ApiService<User.WithdrawWire.Get.Response> => {
  return request.get('/payment/withdraw/wire')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetWithdrawWireService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 申請電匯取款
export const PostWithdrawWireService = (query: User.WithdrawWire.Post.Request) => {
  const body = toUnderline(deepCopy(query))
  return request.post('/payment/withdraw/wire/order', body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostWithdrawWireService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得加密貨幣取款申請表單資訊
export const GetWithDrawCryptoService = (): ApiService<User.WithDrawCrypto.Get.Response> => {
  return request.get('/payment/withdraw/crypto')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetWithDrawCryptoService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 申請加密貨幣取款
export const PostWithdrawCryptoService = (query: User.WithDrawCrypto.Post.Request) => {
  const body = toUnderline(deepCopy(query))
  return request.post('/payment/withdraw/crypto/order', body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostWithdrawCryptoService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
